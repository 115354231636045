var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.isPlayMode && !_vm.selectedRowTaskId)?_c('div',[_c('h3',{staticClass:"table-title"},[_vm._v(_vm._s(_vm.$t('document.documentsOperationOverview.openTasksTable.tableTitle')))]),_c('div',{staticClass:"actions"},[_c('div',{staticClass:"filters"},[_c('CheckableDropdown',{attrs:{"initial-selected-ids":_vm.initialTasksIds,"options":_vm.tasksType,"label":_vm.$t('document.documentsOperationOverview.openTasksTable.filters.task')},on:{"change":_vm.handleTasksTypeSelect}}),_c('MultiSelect',{class:{ 'filter-is-dirty': _vm.selectedBusinesses?.length },attrs:{"title":`${_vm.$t('tasks.tasksPage.filters.business')}${
            _vm.selectedBusinesses.length ? ` (${_vm.selectedBusinesses.length})` : ''
          }`,"options":_vm.businesses,"selected":_vm.selectedBusinesses,"placeholder":`${_vm.$t('tasks.tasksPage.filters.business')}${
            _vm.selectedBusinesses.length ? ` (${_vm.selectedBusinesses.length})` : ''
          }`},on:{"on-select":_vm.handleBusinessSelect,"on-search-change":_vm.handleBusinessSearchChange,"load-more":_vm.handleLoadMoreBusiness}}),_c('DropdownTableFilter',{class:{ 'filter-is-dirty': _vm.selectedReviewOption !== _vm.REVIEW_REQUIRED_OPTION.ALL },attrs:{"trigger":"click","clearable":false,"filter-name":_vm.$t('tasks.tasksPage.filters.reviewRequired.title'),"filter-value":_vm.selectedReviewOption !== _vm.REVIEW_REQUIRED_OPTION.ALL
              ? _vm.$t(`tasks.tasksPage.filters.reviewRequired.${_vm.selectedReviewOption}`)
              : '',"placement":_vm.$direction === 'rtl' ? 'bottom-end' : 'bottom-start'},on:{"on-choose-item":_vm.handleReviewSelect}},[_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.reviewOptions),function(option){return _c('el-dropdown-item',{key:option.id,class:{ 'dropdown-selected-item': _vm.selectedReviewOption === option.id },attrs:{"command":option.id}},[_vm._v(" "+_vm._s(option.name)+" ")])}),1)],1)],1),_c('el-tooltip',{attrs:{"content":_vm.$t(
            _vm.tasksTotalCount < 1 || !_vm.activeTasks?.length
              ? 'tasks.tasksPage.playMode.startBtn.noTasksTooltip'
              : typeof _vm.isReviewRequired === 'undefined'
              ? 'tasks.tasksPage.playMode.startBtn.reviewRequiredTooltip'
              : 'tasks.tasksPage.playMode.startBtn.tooltip'
          ),"placement":"top","effect":"dark"}},[_c('Button',{attrs:{"disabled":_vm.tasksTotalCount < 1 || typeof _vm.isReviewRequired === 'undefined' || !_vm.activeTasks?.length},on:{"click":_vm.showPlayMode}},[_vm._v(" "+_vm._s(_vm.$t('tasks.tasksPage.playMode.startBtn.label'))+" "),_c('PlayIcon',{attrs:{"rotate":true,"size":14}})],1)],1)],1),_c('div',{staticClass:"tasks-table"},[_c('TasksTable',{attrs:{"tasks":_vm.tasks,"page-limit":_vm.PAGE_LIMIT,"current-page":_vm.currentPage,"loading":_vm.tasksLoading || _vm.fakeLoading},on:{"on-row-click":_vm.handleRowClick}})],1),_c('div',{staticClass:"d-flex justify-content-end my-3"},[_c('el-pagination',{attrs:{"small":"","layout":"prev, pager, next, jumper","background":"","current-page":_vm.currentPage,"page-size":_vm.PAGE_LIMIT,"page-count":Math.ceil(_vm.tasksTotalCount / _vm.PAGE_LIMIT),"total":_vm.tasksTotalCount},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}}})],1)]):_c('div',[(!_vm.isLoadingDependencies)?_c('PlayMode',{attrs:{"should-play-single-task":_vm.selectedRowTaskId && !_vm.isPlayMode,"task-id":_vm.selectedRowTaskId,"business-ids":_vm.tasksFilters.businessIds,"domains":_vm.tasksFilters.domains,"types":_vm.tasksFilters.types,"review-required":_vm.tasksFilters.reviewRequired,"tasks-total-count":_vm.tasksTotalCount},on:{"hide-play-mode":_vm.hidePlayMode,"on-task-complete":_vm.onTaskComplete,"on-task-skip":_vm.onTaskSkip}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }