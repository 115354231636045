<template>
  <div>
    <div v-if="!isPlayMode && !selectedRowTaskId">
      <h3 class="table-title">{{ $t('document.documentsOperationOverview.closedTasksTable.tableTitle') }}</h3>
      <div class="actions">
        <div class="filters">
          <CheckableDropdown
            :initial-selected-ids="initialTasksIds"
            :options="tasksType"
            :label="$t('document.documentsOperationOverview.closedTasksTable.filters.task')"
            @change="handleTasksTypeSelect"
          />

          <MultiSelect
            :title="`${$t('tasks.tasksPage.filters.business')}${
              selectedBusinesses.length ? ` (${selectedBusinesses.length})` : ''
            }`"
            :options="businesses"
            :selected="selectedBusinesses"
            :class="{ 'filter-is-dirty': selectedBusinesses?.length }"
            :placeholder="`${$t('tasks.tasksPage.filters.business')}${
              selectedBusinesses.length ? ` (${selectedBusinesses.length})` : ''
            }`"
            @on-select="handleBusinessSelect"
            @on-search-change="handleBusinessSearchChange"
            @load-more="handleLoadMoreBusiness"
          />

          <DatePickerTableFilter
            :filter-name="$t('document.documentsOperationOverview.closedTasksTable.filters.closedAt')"
            :filter-value="dateRangeClosedAtText"
            value-format="yyyy-MM-dd"
            type="daterange"
            size="small"
            :align="$direction === 'rtl' ? 'right' : 'left'"
            :date-range="dateRangeClosedAt"
            :clearable="true"
            @on-date-changed="onChangedDateRangeTaskClosedAt"
            @on-clear-filter="onChangedDateRangeTaskClosedAt(null)"
          />

          <MultiSelect
            :title="`${$t('document.documentsOperationOverview.closedTasksTable.filters.closedBy')}${
              selectedUsers.length ? ` (${selectedUsers.length})` : ''
            }`"
            :options="users"
            :selected="selectedUsers"
            :class="{ 'filter-is-dirty': selectedUsers?.length }"
            :placeholder="`${$t('document.documentsOperationOverview.closedTasksTable.filters.userSearchPlaceholder')}${
              selectedUsers.length ? ` (${selectedUsers.length})` : ''
            }`"
            @on-select="handleUserSelect"
            @on-search-change="handleUsersSearchChange"
          />

          <DatePickerTableFilter
            :filter-name="$t('document.documentsOperationOverview.closedTasksTable.filters.createdAt')"
            :filter-value="dateRangeCreatedAtText"
            value-format="yyyy-MM-dd"
            type="daterange"
            size="small"
            :align="$direction === 'rtl' ? 'right' : 'left'"
            :date-range="dateRangeCreatedAt"
            :clearable="true"
            @on-date-changed="onChangedDateRangeTaskCreatedAt"
            @on-clear-filter="onChangedDateRangeTaskCreatedAt(null)"
          />
        </div>
      </div>
      <div class="tasks-table">
        <ClosedTasksTable
          :tasks="tasks"
          :page-limit="PAGE_LIMIT"
          :current-page="currentPage"
          :loading="tasksLoading || fakeLoading"
          @on-row-click="handleRowClick"
        />
      </div>

      <div class="d-flex justify-content-end my-3">
        <el-pagination
          small
          layout="prev, pager, next, jumper"
          background
          :current-page.sync="currentPage"
          :page-size="PAGE_LIMIT"
          :page-count="Math.ceil(tasksTotalCount / PAGE_LIMIT)"
          :total="tasksTotalCount"
        />
      </div>
    </div>

    <div v-else>
      <PlayMode
        :should-play-single-task="selectedRowTaskId && !isPlayMode"
        :task-id="selectedRowTaskId"
        :business-ids="tasksFilters.businessIds"
        :domains="tasksFilters.domains"
        :types="tasksFilters.types"
        :review-required="tasksFilters.reviewRequired"
        :tasks-total-count="tasksTotalCount"
        @hide-play-mode="hidePlayMode"
        @on-task-complete="onTaskComplete"
        @on-task-skip="onTaskSkip"
      />
    </div>
  </div>
</template>

<script type="text/javascript">
import { computed, getCurrentInstance, onMounted, onUnmounted, ref, watch } from 'vue';
import { TASK_TYPE, REVIEW_REQUIRED_OPTION } from './configuration';
import { uniqBy, debounce } from 'lodash';
import { useRoute, useRouter } from 'vue-router/composables';
import { useTenancy } from '@/modules/auth';
import { useAllTasks } from './compositions';
import { useAccumulativeBusinesses } from '../contactSet/composition/businesses';
import ClosedTasksTable from './components/ClosedTasksTable.vue';
import { MultiSelect } from './components';
import { useBusinessByNames } from '../business/compositions/business';
import { CheckableDropdown, DatePickerTableFilter } from './task/components/table-filters';
import { DateTime } from 'luxon';
import { omit, isNil } from 'ramda';
import { useUsers } from '@/modules/users/compositions';

import PlayMode from './components/PlayMode.vue';

const PAGE_LIMIT = 15;
const BUSINESSES_LIMIT = 100;
const FIRST_TIME_FAKE_LOADING_TIME_MS = 500;
const TASKS_REFETCH_INTERVAL_MS = 60000;

export default {
  components: {
    ClosedTasksTable,
    MultiSelect,
    PlayMode,
    CheckableDropdown,
    DatePickerTableFilter,
  },
  setup() {
    const root = getCurrentInstance().proxy;
    const route = useRoute();
    const router = useRouter();
    const currentPage = ref(1);
    const searchFilter = ref({ value: '', debounced: '' });
    const after = ref(0);
    const selectedBusinesses = ref([]);
    const intervalId = ref(null);

    const usersSearch = ref('');
    const selectedUsers = ref([]);

    const { users } = useUsers(usersSearch);

    const presentedUsers = computed(() => {
      const systemUser = {
        id: 'system',
        name: root.$i18n.t(`tasks.tasksPage.closedTasksTable.fields.systemUser`),
      };

      if (isNil(users.value)) return [systemUser];

      const searchUsers = users.value.map(({ given_name, family_name, id }) => ({
        id,
        name: `${given_name} ${family_name}`,
      }));

      searchUsers.unshift(systemUser);
      return uniqBy([...selectedUsers.value, ...searchUsers], 'id');
    });

    const selectedUserIds = computed(() => {
      return selectedUsers.value.map((user) => {
        return user.id;
      });
    });

    const getDateRangeText = (date) => {
      if (!date) return '';
      const inputFormat = 'yyyy-MM-dd';
      const from = DateTime.fromFormat(date[0], inputFormat).toLocaleString(DateTime.DATE_SHORT, root.$i18n.locale);
      const to = DateTime.fromFormat(date[1], inputFormat).toLocaleString(DateTime.DATE_SHORT, root.$i18n.locale);
      return root.$direction === 'rtl' ? `${to} - ${from}` : `${from} - ${to}`;
    };

    const tasksFromQueryParams =
      route.query.tasks?.split(',').reduce((acc, task) => {
        return [...acc, { id: task }];
      }, []) || [];

    const selectedTasksTypes = ref(tasksFromQueryParams);

    const businessesWithSelected = ref([]);
    const businessesParam = ref(route.query.businessIds?.split(','));
    const isFirstFetchBusinesses = ref(true);
    const isPlayMode = ref(false);
    const fakeLoading = ref(true);
    const timeoutId = ref(null);
    const selectedRowTaskId = ref(null);

    const closedAtRangeFromQueryParams = () => {
      const from = route.query?.closedAtFrom;
      const to = route.query?.closedAtTo;
      if (from && to) {
        return [from, to];
      }
    };

    const closedAtRangeTextFromQueryParams = () => {
      const from = route.query?.closedAtFrom;
      const to = route.query?.closedAtTo;
      if (from && to) {
        return getDateRangeText([from, to]);
      }
    };

    const dateRangeClosedAtText = ref(closedAtRangeTextFromQueryParams());
    const dateRangeClosedAt = ref(closedAtRangeFromQueryParams());

    const createdAtRangeFromQueryParams = () => {
      const from = route.query?.createdAtFrom;
      const to = route.query?.createdAtTo;
      if (from && to) {
        return [from, to];
      }
    };

    const createdAtRangeTextFromQueryParams = () => {
      const from = route.query?.createdAtFrom;
      const to = route.query?.createdAtTo;
      if (from && to) {
        return getDateRangeText([from, to]);
      }
    };

    const dateRangeCreatedAtText = ref(createdAtRangeTextFromQueryParams());
    const dateRangeCreatedAt = ref(createdAtRangeFromQueryParams());

    onMounted(() => {
      timeoutId.value = setTimeout(() => {
        fakeLoading.value = false;
      }, FIRST_TIME_FAKE_LOADING_TIME_MS);
    });

    onUnmounted(() => {
      if (timeoutId.value) {
        clearTimeout(timeoutId.value);
      }
    });

    const reviewOptions = ref([
      {
        id: REVIEW_REQUIRED_OPTION.ALL,
        name: root.$i18n.t(`tasks.tasksPage.filters.all`),
      },
      {
        id: REVIEW_REQUIRED_OPTION.REVIEW_REQUIRED,
        name: root.$i18n.t(`tasks.tasksPage.filters.reviewRequired.${REVIEW_REQUIRED_OPTION.REVIEW_REQUIRED}`),
      },
      {
        id: REVIEW_REQUIRED_OPTION.REVIEW_NOT_REQUIRED,
        name: root.$i18n.t(`tasks.tasksPage.filters.reviewRequired.${REVIEW_REQUIRED_OPTION.REVIEW_NOT_REQUIRED}`),
      },
    ]);

    const { result: firstFetchSelectedBusinesses } = useBusinessByNames({
      businessIds: computed(() => businessesParam.value || []),
    });

    const { currentTenant } = useTenancy();

    const { businesses, clearBusinesses } = useAccumulativeBusinesses(
      computed(() => ({
        ...(searchFilter.value.debounced !== '' && { search: searchFilter.value.debounced }),
      })),
      BUSINESSES_LIMIT,
      after
    );

    const tasksType = computed(() =>
      Object.keys(TASK_TYPE).map((key) => ({
        id: TASK_TYPE[key],
        name: root.$i18n.t(`tasks.tasksPage.tasksName.${TASK_TYPE[key]}`),
        isPermitted: true,
      }))
    );

    const selectedBusinessIds = computed(() => [...new Set(selectedBusinesses.value.map((business) => business.id))]);

    watch(
      [businessesParam, firstFetchSelectedBusinesses],
      () => {
        if (
          isFirstFetchBusinesses.value &&
          businessesParam.value?.length > 0 &&
          businessesParam.value?.length === firstFetchSelectedBusinesses?.value.length
        ) {
          selectedBusinesses.value = firstFetchSelectedBusinesses.value;
          isFirstFetchBusinesses.value = false;
        }
      },
      { immediate: true, deep: true }
    );

    watch(
      selectedBusinessIds,
      debounce(
        () => {
          const query = {
            ...route.query,
          };

          if (selectedBusinessIds?.value?.length === 0) {
            delete query.businessIds;
          } else {
            query.businessIds = selectedBusinessIds.value.join(',');
          }
          router.replace({ query });
        },
        { immediate: true, deep: true }
      )
    );

    const selectedTypeAndDomain = computed(() => {
      // Default tasks type and domain query
      if (!selectedTasksTypes.value.length) {
        const domainTypePairs = Object.values(TASK_TYPE) || [];

        const domainType = domainTypePairs.reduce(
          (acc, domainTypePair) => {
            const [type, domain] = domainTypePair.split('_');
            acc.types.push(type);
            acc.domains.push(domain);
            return acc;
          },
          { types: [], domains: [] }
        );
        return domainType;
      }

      const domains = [];
      const types = [];

      for (const item of selectedTasksTypes.value) {
        const [type, domain] = item.id.split('_');

        if (domain) domains.push(domain);
        if (type) types.push(type);
      }

      return { types, domains };
    });

    watch(
      selectedTypeAndDomain,
      debounce(() => {
        const query = {
          ...route.query,
        };

        if (!selectedTasksTypes.value?.length) {
          delete query.tasks;
        } else {
          query.tasks = selectedTasksTypes.value.map((item) => item.id).join(',');
        }

        router.replace({ query });
      }),
      { immediate: true, deep: true }
    );

    const tasksFilters = computed(() => {
      const [completedAtFrom, completedAtTo] = dateRangeClosedAt.value ?? [];
      const [createdAtFrom, createdAtTo] = dateRangeCreatedAt.value ?? [];
      return {
        businessIds: selectedBusinessIds.value,
        completed: true,
        domains: selectedTypeAndDomain.value.domains,
        types: selectedTypeAndDomain.value.types,
        completedAtFrom,
        completedAtTo,
        createdAtFrom,
        createdAtTo,
        completedBy: selectedUserIds.value,
        limit: PAGE_LIMIT,
        offset: (currentPage.value - 1) * PAGE_LIMIT,
      };
    });

    const {
      tasks,
      totalCount: tasksTotalCount,
      loading: tasksLoading,
      refetch: refetchTasks,
    } = useAllTasks(tasksFilters);

    onMounted(() => {
      intervalId.value = setInterval(() => {
        refetchTasks();
      }, TASKS_REFETCH_INTERVAL_MS);
    });

    onUnmounted(() => {
      if (intervalId.value) {
        clearInterval(intervalId.value);
      }
    });

    const updateBusinessesWithSelected = debounce(() => {
      businessesWithSelected.value = uniqBy(
        [...firstFetchSelectedBusinesses.value, ...selectedBusinesses.value, ...businesses.value],
        'id'
      );
    }, 50);

    watch([searchFilter, businesses, selectedBusinesses], updateBusinessesWithSelected, {
      immediate: true,
      deep: true,
    });

    const handleBusinessSelect = (selectedItems) => {
      selectedBusinesses.value = selectedItems;
    };

    const handleUserSelect = (selectedItems) => {
      selectedUsers.value = selectedItems;
    };

    const handleUsersSearchChange = debounce((newSearchText) => {
      usersSearch.value = newSearchText || '';
    }, 300);

    const handleBusinessSearchChange = debounce((newSearchText) => {
      clearBusinesses();
      searchFilter.value.value = newSearchText || '';
      searchFilter.value.debounced = newSearchText || '';
      after.value = 0;
    }, 300);

    const handleLoadMoreBusiness = () => {
      after.value += 1;
    };

    const handleTasksTypeSelect = (selectedItem) => {
      selectedTasksTypes.value = selectedItem;
    };

    const showPlayMode = () => {
      isPlayMode.value = true;
    };

    const hidePlayMode = () => {
      isPlayMode.value = false;
      selectedRowTaskId.value = null;
    };

    watch(dateRangeClosedAt, () => {
      const [from, to] = dateRangeClosedAt.value ?? [];
      let query = {
        ...route.query,
      };

      if (isNil(from) || isNil(to)) {
        query = omit(['closedAtFrom', 'closedAtTo'], query);
      } else {
        query.closedAtFrom = from;
        query.closedAtTo = to;
      }

      router.replace({ query });
    });

    watch(dateRangeCreatedAt, () => {
      const [from, to] = dateRangeCreatedAt.value ?? [];
      let query = {
        ...route.query,
      };

      if (isNil(from) || isNil(to)) {
        query = omit(['createdAtFrom', 'createdAtTo'], query);
      } else {
        query.createdAtFrom = from;
        query.createdAtTo = to;
      }

      router.replace({ query });
    });

    watch(
      route,
      () => {
        selectedRowTaskId.value = route.query.task;
      },
      { immediate: true, deep: true }
    );

    watch(tasks, () => {
      if (!tasks.value.length) {
        isPlayMode.value = false;
      }
    });

    const activeTasks = computed(() => tasks?.value?.filter((task) => task.activeAt <= Date.now()) || []);

    const handleRowClick = (rowId) => {
      const path = `tasks?task=${rowId}`;
      router.resolve({ path }).href;
      window.open(path, '_blank');
    };

    const onChangedDateRangeTaskClosedAt = (date) => {
      dateRangeClosedAt.value = date ?? null;
      dateRangeClosedAtText.value = getDateRangeText(date);
    };

    const onChangedDateRangeTaskCreatedAt = (date) => {
      dateRangeCreatedAt.value = date ?? null;
      dateRangeCreatedAtText.value = getDateRangeText(date);
    };

    const onTaskComplete = refetchTasks; // remove?
    const onTaskSkip = refetchTasks; // remove?

    return {
      initialTasksIds: tasksFromQueryParams.map((t) => t.id),
      PAGE_LIMIT,
      currentPage,
      tasks: computed(() => tasks.value || []),
      tasksLoading,
      tasksTotalCount,
      currentTenant,
      selectedBusinesses,
      handleBusinessSelect,
      handleBusinessSearchChange,
      handleLoadMoreBusiness,
      businesses: businessesWithSelected,
      reviewOptions,
      handleTasksTypeSelect,
      tasksType,
      selectedTasksTypes,
      REVIEW_REQUIRED_OPTION,
      fakeLoading,
      showPlayMode,
      isPlayMode,
      selectedRowTaskId,
      tasksFilters,
      handleRowClick,
      onTaskComplete,
      onTaskSkip,
      hidePlayMode,
      activeTasks,
      dateRangeClosedAtText,
      dateRangeClosedAt,
      onChangedDateRangeTaskClosedAt,
      dateRangeCreatedAtText,
      dateRangeCreatedAt,
      onChangedDateRangeTaskCreatedAt,
      users: presentedUsers,
      handleUserSelect,
      selectedUsers,
      handleUsersSearchChange,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .filter-is-dirty {
    .filter-name {
      color: #1f284d;
    }

    .filter {
      border: 1px solid #1f284d;
    }
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.dropdown-selected-item {
  color: #3661dd;
  background-color: #f5f7fa;
}

.container {
  min-width: 1100px;
  max-width: unset;
}

.filters {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-start;
}

.title {
  display: flex;
  max-width: 1320px;
}

.table-title {
  margin-bottom: 20px !important;
}
</style>
