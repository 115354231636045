var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.isPlayMode && !_vm.selectedRowTaskId)?_c('div',[_c('h3',{staticClass:"table-title"},[_vm._v(_vm._s(_vm.$t('document.documentsOperationOverview.closedTasksTable.tableTitle')))]),_c('div',{staticClass:"actions"},[_c('div',{staticClass:"filters"},[_c('CheckableDropdown',{attrs:{"initial-selected-ids":_vm.initialTasksIds,"options":_vm.tasksType,"label":_vm.$t('document.documentsOperationOverview.closedTasksTable.filters.task')},on:{"change":_vm.handleTasksTypeSelect}}),_c('MultiSelect',{class:{ 'filter-is-dirty': _vm.selectedBusinesses?.length },attrs:{"title":`${_vm.$t('tasks.tasksPage.filters.business')}${
            _vm.selectedBusinesses.length ? ` (${_vm.selectedBusinesses.length})` : ''
          }`,"options":_vm.businesses,"selected":_vm.selectedBusinesses,"placeholder":`${_vm.$t('tasks.tasksPage.filters.business')}${
            _vm.selectedBusinesses.length ? ` (${_vm.selectedBusinesses.length})` : ''
          }`},on:{"on-select":_vm.handleBusinessSelect,"on-search-change":_vm.handleBusinessSearchChange,"load-more":_vm.handleLoadMoreBusiness}}),_c('DatePickerTableFilter',{attrs:{"filter-name":_vm.$t('document.documentsOperationOverview.closedTasksTable.filters.closedAt'),"filter-value":_vm.dateRangeClosedAtText,"value-format":"yyyy-MM-dd","type":"daterange","size":"small","align":_vm.$direction === 'rtl' ? 'right' : 'left',"date-range":_vm.dateRangeClosedAt,"clearable":true},on:{"on-date-changed":_vm.onChangedDateRangeTaskClosedAt,"on-clear-filter":function($event){return _vm.onChangedDateRangeTaskClosedAt(null)}}}),_c('MultiSelect',{class:{ 'filter-is-dirty': _vm.selectedUsers?.length },attrs:{"title":`${_vm.$t('document.documentsOperationOverview.closedTasksTable.filters.closedBy')}${
            _vm.selectedUsers.length ? ` (${_vm.selectedUsers.length})` : ''
          }`,"options":_vm.users,"selected":_vm.selectedUsers,"placeholder":`${_vm.$t('document.documentsOperationOverview.closedTasksTable.filters.userSearchPlaceholder')}${
            _vm.selectedUsers.length ? ` (${_vm.selectedUsers.length})` : ''
          }`},on:{"on-select":_vm.handleUserSelect,"on-search-change":_vm.handleUsersSearchChange}}),_c('DatePickerTableFilter',{attrs:{"filter-name":_vm.$t('document.documentsOperationOverview.closedTasksTable.filters.createdAt'),"filter-value":_vm.dateRangeCreatedAtText,"value-format":"yyyy-MM-dd","type":"daterange","size":"small","align":_vm.$direction === 'rtl' ? 'right' : 'left',"date-range":_vm.dateRangeCreatedAt,"clearable":true},on:{"on-date-changed":_vm.onChangedDateRangeTaskCreatedAt,"on-clear-filter":function($event){return _vm.onChangedDateRangeTaskCreatedAt(null)}}})],1)]),_c('div',{staticClass:"tasks-table"},[_c('ClosedTasksTable',{attrs:{"tasks":_vm.tasks,"page-limit":_vm.PAGE_LIMIT,"current-page":_vm.currentPage,"loading":_vm.tasksLoading || _vm.fakeLoading},on:{"on-row-click":_vm.handleRowClick}})],1),_c('div',{staticClass:"d-flex justify-content-end my-3"},[_c('el-pagination',{attrs:{"small":"","layout":"prev, pager, next, jumper","background":"","current-page":_vm.currentPage,"page-size":_vm.PAGE_LIMIT,"page-count":Math.ceil(_vm.tasksTotalCount / _vm.PAGE_LIMIT),"total":_vm.tasksTotalCount},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}}})],1)]):_c('div',[_c('PlayMode',{attrs:{"should-play-single-task":_vm.selectedRowTaskId && !_vm.isPlayMode,"task-id":_vm.selectedRowTaskId,"business-ids":_vm.tasksFilters.businessIds,"domains":_vm.tasksFilters.domains,"types":_vm.tasksFilters.types,"review-required":_vm.tasksFilters.reviewRequired,"tasks-total-count":_vm.tasksTotalCount},on:{"hide-play-mode":_vm.hidePlayMode,"on-task-complete":_vm.onTaskComplete,"on-task-skip":_vm.onTaskSkip}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }